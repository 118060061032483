import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import { NavbarDesktop } from './desktop';
import { NavbarMobile } from './mobile';
import { Menu } from '../../../dtos/Menu';

type Props = {
	menu: Menu[];
	title: string;
};

export const Navbar = ({ menu, title }: Props) => {
	const [showMobile, setShowMobile] = useState(false);

	return (
		<nav className="bg-white dark:bg-gray-800 shadow flex-1 ">
			<div className="w-full mx-auto px-8">
				<div className="flex items-center justify-between h-16">
					<NavbarDesktop menu={menu} title={title} />
					<div className="-mr-2 flex md:hidden">
						<button
							className="text-gray-800 dark:text-white inline-flex items-center justify-center p-2 rounded-md focus:outline-none"
							onClick={() => setShowMobile(!showMobile)}>
							<FontAwesomeIcon icon={showMobile ? faXmark : faBars} className="w-5 h-5" />
						</button>
					</div>
				</div>
			</div>
			<NavbarMobile menu={menu} showMenu={showMobile} />
		</nav>
	);
};

export default Navbar;
