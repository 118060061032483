import sendgrid from '@sendgrid/mail';
import type { NextApiRequest, NextApiResponse } from 'next';

export async function contactApi(
	title: string,
	req: NextApiRequest,
	res: NextApiResponse<unknown>
) {
	sendgrid.setApiKey(process.env.SENDGRID_API_KEY!);
	const message = {
		to: 'juan@altairstudios.es', // Change to your recipient
		from: 'juan@altairstudios.es', // Change to your verified sender
		subject: `NICHOS: ${title} - Contacto: ${req.body.email}`,
		text: req.body.message
		//html: '<strong>and easy to do anywhere, even with Node.js</strong>'
	};
	sendgrid
		.send(message)
		.then(() => {
			res.status(200).json(true);
		})
		.catch((error) => {
			res.status(500).json(true);
		});
}
