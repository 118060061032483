import React from 'react';
import { News } from '../../dtos';
import { NewsCardComponent, StaticImport } from './news-card';

type Props = {
	news?: News[];
	defaultImage: string | StaticImport;
};

export const NewsGridComponent = ({ news, defaultImage }: Props) => {
	return (
		<section className="text-gray-600 body-font">
			<div className="container px-5 py-5 mx-auto">
				<div className="flex flex-wrap -m-4">
					{news!.map((aNew: News) => {
						return <NewsCardComponent key={aNew.code} aNew={aNew} defaultImage={defaultImage} />;
					})}
				</div>
			</div>
		</section>
	);
};
