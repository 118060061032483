import '../styles/globals.css';
import type { AppProps } from 'next/app';
import Header from '../components/header';
import { initializeApp } from 'firebase/app';
import { initializeAnalytics, logEvent, getAnalytics } from 'firebase/analytics';
import { useEffect } from 'react';
import { SessionProvider } from 'next-auth/react';
import 'reflect-metadata';
import Script from 'next/script';
import { Footer, Navbar } from 'ui';
import Link from 'next/link';
import Image from 'next/image';
import { getMenus } from '../models/Menu';
import { Analytics } from '@vercel/analytics/react';

const firebaseConfig = {
	apiKey: 'AIzaSyB0WlX0mBFqWXtJEw0EfYeaGK9fc7j5iqs',
	authDomain: 'cooches-df6ff.firebaseapp.com',
	databaseURL: 'https://cooches-df6ff.firebaseio.com',
	projectId: 'cooches-df6ff',
	storageBucket: 'cooches-df6ff.appspot.com',
	messagingSenderId: '187360853849',
	appId: '1:187360853849:web:33c6d8fe6c32f368879648'
};

const app = initializeApp(firebaseConfig);

type PageType = {
	title?: string;
};

function MyApp({ Component, pageProps }: AppProps<PageType>) {
	useEffect(() => {
		if (process.env.NODE_ENV === 'production') {
			initializeAnalytics(app);
		}
	}, []);

	const MyComponent: any = Component;

	return (
		<SessionProvider basePath="/api/auth" baseUrl="/">
			<Header title={pageProps.title || 'Cooches'} />
			<main className="dark:bg-gray-800 bg-white h-screen">
				<header className="flex items-center z-30 w-full">
					<Navbar menu={getMenus()} />
				</header>
				<div className="bg-white dark:bg-gray-800 flex z-20 items-center overflow-hidden">
					<div className="w-full mx-auto px-6 flex py-5 justify-center">
						<MyComponent {...pageProps} />
					</div>
				</div>
				<Footer
					logo={
						<Link href="/" className="flex items-center">
							<Image
								src="/images/logos/logo.png"
								className="h-10 w-8 object-contain"
								height={100}
								width={100}
								alt="ayuda finanzas"
							/>
						</Link>
					}
					pages={getMenus()}
				/>
			</main>
			<Script src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7520941738674114" />
			<Analytics />
		</SessionProvider>
	);
}

export default MyApp;

export function reportWebVitals({
	id,
	name,
	label,
	value
}: {
	id: string;
	name: string;
	label: string;
	value: number;
}) {
	logEvent(getAnalytics(), name, {
		category: label,
		value: Math.round(name === 'CLS' ? value * 1000 : value),
		page: id
	});
}
