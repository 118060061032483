import Head from 'next/head';

type HeaderProps = {
	title: string;
};

const Header = (props: HeaderProps) => {
	const title = `${props.title} - Ayuda Finanzas, aprende sobre economía y a gestionar tus finanzas.`;

	return (
		<Head>
			<title>{title}</title>
			<link rel="icon" href="/favicon.ico" />
			<meta name="title" content={title} />
			<meta
				name="description"
				content="Ayuda Finanzas, aprende sobre economía y a gestionar tus finanzas."
			/>
			<meta
				name="keywords"
				content="ayuda, finanzas, economía, préstamos, hipotecas, presupuesto"
			/>
			<meta name="robots" content="index, follow" />
			<meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
			<meta name="language" content="Spanish" />
			<meta name="revisit-after" content="1 days" />
		</Head>
	);
};

export default Header;
