import Image from 'next/image';
import _ from 'lodash';
import { marked } from 'marked';
import fs from 'fs';
import { FunctionComponent } from 'react';
import { News, NewsCategory } from '../../dtos';

type Props = {
	aNew: News;
	category: NewsCategory;
	content: string;
};

type StaticProps = {
	category: NewsCategory;
	aNew: News;
};

export const ANewPageComponent: FunctionComponent<Props> = ({ aNew, category, content }: Props) => {
	const tags = aNew.tags.map((tag: string) => {
		return (
			<a
				key={tag}
				href="#"
				className="m-1 px-4 py-1 bg-black text-gray-200 inline-flex items-center justify-center mb-2">
				{tag}
			</a>
		);
	});

	return (
		<main className="mt-10">
			<div className="mb-4 md:mb-0 w-full max-w-screen-md mx-aut">
				<div className=" left-0 bottom-0 w-full h-full z-10"></div>
				<Image
					src={aNew.image!}
					className="lg:h-48 md:h-36 w-full object-cover object-center scale-110 transition-all duration-400 hover:scale-100"
					alt={`Cooches - ${aNew.name}`}
					title={`Cooches - ${aNew.name}`}
					width={800}
					height={450}
				/>

				<div className="p-4 bottom-0">
					{tags}
					<h1 className="text-4xl font-semibold dark:text-gray-300 text-gray-700 leading-tight">
						{aNew.name}
					</h1>
					{/* <div className="flex mt-3">
						<img
							src="https://randomuser.me/api/portraits/men/97.jpg"
							className="h-10 w-10 rounded-full mr-2 object-cover"
						/>
						<div>
							<p className="font-semibold text-gray-200 text-sm"> Mike Sullivan </p>
							<p className="font-semibold text-gray-400 text-xs"> 14 Aug </p>
						</div>
					</div> */}
				</div>
			</div>

			<div
				className="px-4 lg:px-0 mt-12 dark:text-gray-300 text-gray-700 max-w-screen-md mx-auto text-lg leading-relaxed"
				dangerouslySetInnerHTML={{ __html: content }}></div>
		</main>
	);
};

export async function ANewPageStaticProps({ category, aNew }: StaticProps) {
	const md = fs.readFileSync(`${process.cwd()}/src/data/news/${aNew?.template}.md`, 'utf8');

	return {
		props: {
			content: marked.parse(md, {}, (err, result) => {
				result = result.replace(
					/<h2 id=(.*)>/gm,
					'<h2 class="text-3xl font-semibold dark:text-gray-300 text-gray-700 mt-5 mb-5" id=$1>'
				);

				result = result.replace(/<p>/gm, '<p class="mt-5">');
				result = result.replace(/<ul>/gm, '<ul class="list-disc ml-7">');
				result = result.replace(/<a/gm, '<a class="underline"');

				return result;
			}),
			category,
			aNew,
			title: `Noticias - ${aNew?.name}`
		}
	};
}
