import Link from 'next/link';
import { useRouter } from 'next/router';
import { Menu } from '../../../dtos/Menu';

type Props = {
	showMenu: boolean;
	menu: Menu[];
};

export const NavbarMobile = ({ showMenu, menu }: Props) => {
	const router = useRouter();
	const path = router.asPath;
	const mobileShowClass = showMenu ? 'block' : 'hidden';

	const navbarStyle =
		'hover:text-gray-800 dark:hover:text-white block px-3 py-2 rounded-md text-base font-medium';

	return (
        <div className={`${mobileShowClass} md:hidden`}>
			<div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
				{menu.map((item) => {
					const isSelected = item.link === '/' ? path === '/' : path.includes(item.link);
					const classHighlight = isSelected ? 'text-gray-800' : 'text-gray-400';

					return (
                        (<Link
                            href={item.link}
                            className={`${navbarStyle} ${classHighlight}`}
                            title={item.title}>

                            {item.title}

                        </Link>)
                    );
				})}
			</div>
		</div>
    );
};
